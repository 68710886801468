import type { FunctionComponent } from 'react';
import type { TypeConditionalWrapperProps } from './types';

/**
 * ConditionalWrapper
 */
export const ConditionalWrapper: FunctionComponent<
  TypeConditionalWrapperProps
> = ({ condition, wrapper, children }: TypeConditionalWrapperProps) =>
  condition ? wrapper(children) : (children ?? null);

ConditionalWrapper.displayName = 'ConditionalWrapper';
