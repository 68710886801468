import Image from 'next/image';

import type { FunctionComponent } from 'react';
import type { TypeBackgroundImageProps } from './types';

/**
 * BackgroundImage
 */
export const BackgroundImage: FunctionComponent<TypeBackgroundImageProps> = ({
  backgroundImage,
  overlay,
  notLazy,
  priority = false,
}: TypeBackgroundImageProps) => (
  <>
    {backgroundImage && typeof backgroundImage !== 'string' && (
      <div className="absolute inset-0">
        {backgroundImage.srcset.map((source: any, index: number) => {
          let className = '';
          if (index === 0) {
            className = `hidden ${source.media}:block`;
          } else if (index === backgroundImage.srcset.length - 1) {
            className = `${backgroundImage.srcset.at(index - 1)?.media}:hidden`;
          } else {
            className = `hidden ${
              source.media
            }:block ${backgroundImage.srcset.at(index - 1)?.media}:hidden`;
          }

          return (
            <div
              key={index}
              className={`${className} pointer-events-none relative h-full w-full`}
            >
              <Image
                src={source.src}
                alt=""
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                draggable={false}
                loading={notLazy ? 'eager' : 'lazy'}
                priority={priority}
              />
            </div>
          );
        })}
        <div
          className={`${
            backgroundImage.srcset.length > 0 &&
            `${backgroundImage.srcset.at(-1)?.media}:hidden`
          } pointer-events-none relative h-full w-full`}
        >
          <Image
            src={backgroundImage.src}
            alt=""
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            draggable={false}
            loading={notLazy ? 'eager' : 'lazy'}
            priority={priority}
          />
        </div>
      </div>
    )}

    {overlay ? (
      <div className="absolute inset-0 bg-black" style={{ opacity: overlay }} />
    ) : null}
  </>
);
