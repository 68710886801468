/* eslint-disable sonarjs/max-switch-cases */
import dynamic from 'next/dynamic';

import { isProduction } from '@/lib/utils';

import type { TypeFlexibleContextGraphQL } from '@/lib/graphql/types';
import type { TypePostTypeWordpress } from '@/types';
import type { ComponentType, FunctionComponent } from 'react';

const Banner = dynamic(() => import('./Banner/Banner'), {
  ssr: true,
});
const BannerEtiquetaPersonalizada = dynamic(
  () => import('./BannerEtiquetaPersonalizada/BannerEtiquetaPersonalizada'),
  {
    ssr: true,
  },
);
const PorqueComprar = dynamic(() => import('./PorqueComprar/PorqueComprar'), {
  ssr: true,
});
const BloqueProductos = dynamic(() => import('./Productos/Productos'), {
  ssr: true,
});
const BloqueProductosCategoria = dynamic(
  () => import('./ProductosCategory/Productos'),
  {
    ssr: true,
  },
);

const BloqueProductosMarca = dynamic(
  () => import('./ProductsByBrand/ProductsByBrand'),
  {
    ssr: true,
  },
);

const BloqueExperienciasCategoria = dynamic(
  () => import('./ExperienciasCategory/Productos'),
  {
    ssr: true,
  },
);
const BloqueCardsVideo = dynamic(() => import('./CardsVideo/Productos'), {
  ssr: true,
});
const BannerStripComplete = dynamic(
  () => import('./BannerStrip/BannerStripComplete'),
  {
    ssr: true,
  },
);
const BloqueAccesorios = dynamic(() => import('./Accesorios/Accesorios'), {
  ssr: true,
});
const Marcas = dynamic(() => import('./Marcas/Marcas'), {
  ssr: true,
});
const BloqueExperiencias = dynamic(
  () => import('./Experiencias/Experiencias'),
  {
    ssr: true,
  },
);
const BreadCrumbs = dynamic(() => import('./BreadCrumbs/BreadCrumbs'), {
  ssr: true,
});
const BloqueH = dynamic(() => import('./BloqueH/BloqueH'), {
  ssr: true,
});
const Ventajas = dynamic(() => import('./Ventajas/Ventajas'), {
  ssr: true,
});
const StepperNumeros = dynamic(
  () => import('./StepperNumeros/StepperNumeros'),
  {
    ssr: true,
  },
);
const ListOfCategories = dynamic(
  () => import('./ListOfCategories/ListOfCategories'),
  {
    ssr: true,
  },
);

const ListOfCategoriesImages = dynamic(
  () => import('./ListOfCategoriesImages/ListOfCategoriesImages'),
  {
    ssr: true,
  },
);

const Stepper = dynamic(() => import('./Stepper/Stepper'), {
  ssr: true,
});
const StepperSlider = dynamic(() => import('./StepperSlider/StepperSlider'), {
  ssr: true,
});
const HeadBigcrafters = dynamic(
  () => import('./HeadBigcrafters/HeadBigcrafters'),
  {
    ssr: true,
  },
);
const TextColumns = dynamic(
  () => import('./TextColumns/TextColumns').then((mod) => mod.TextColumns),
  {
    ssr: true,
  },
);
const Faqs = dynamic(() => import('./Faqs/Faqs'), {
  ssr: true,
});
const FaqsImagen = dynamic(() => import('./FaqsImagen/FaqsImagen'), {
  ssr: true,
});
const Category = dynamic(() => import('@/pages/category'), {
  ssr: true,
});

const BackgroundVideoText = dynamic(
  () =>
    import('./BackgroundVideoText/BackgroundVideoText').then(
      (mod) => mod.BackgroundVideoText,
    ),
  {
    ssr: true,
  },
);

const RecommendsConnectif = dynamic(
  () => import('./RecommendsConnectif/RecommendsConnectif'),
  {
    ssr: true,
  },
);

const SliderCards = dynamic(() => import('./SliderCards/SliderCards'), {
  ssr: true,
});

const Votaciones = dynamic(() => import('./Votaciones/Votaciones'), {
  ssr: true,
});

const BannerUltraSlim = dynamic(
  () => import('./BannerUltraSlim/BannerUltraSlim'),
  {
    ssr: true,
  },
);

const Mosaic = dynamic(() => import('./Mosaic/Mosaic'), {
  ssr: true,
});

const SliderShadowBox = dynamic(
  () => import('./SliderShadowBox/SliderShadowBox'),
  { ssr: true },
);

const TimeLine = dynamic(() => import('./TimeLine/TimeLine'), {
  ssr: true,
});

const Recipe = dynamic(() => import('./Recipe/Recipe'), {
  ssr: true,
});

const StartChatbot = dynamic(() => import('./StartChatbot/StartChatbot'), {
  ssr: true,
});

const Blog = dynamic(() => import('./Blog/Blog').then((mod) => mod.Blog), {
  ssr: true,
});

const VideoImage = dynamic(() => import('./VideoImage/VideoImage'));

type TypeFlexible = {
  flexible: any;
  typeFlexible?: TypeFlexibleContextGraphQL;
  type: TypePostTypeWordpress;
  productos?: [any];
  className?: string;
};

/**
 * FlexibleContent
 */
export const FlexibleContent: FunctionComponent<TypeFlexible> = ({
  type,
  typeFlexible = 'Contenidoflexible',
  flexible,
  productos,
  className = '',
}: TypeFlexible) =>
  flexible?.map((bloque: any, index: number) => {
    let Block: ComponentType<any> = () => null;
    let otherProps = {};

    switch (bloque?.fieldGroupName) {
      case `${type}_${typeFlexible}_Flexible_Banner`:
        Block = Banner;
        break;
      case `${type}_${typeFlexible}_Flexible_BannerEtiquetaPersonalizada`:
        Block = BannerEtiquetaPersonalizada;
        break;
      case `${type}_${typeFlexible}_Flexible_Porquecomprar`:
        Block = PorqueComprar;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueProductos`:
        Block = BloqueProductos;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueProductosCategoria`:
        Block = BloqueProductosCategoria;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueProductosMarca`:
        if (bloque.hasOwnProperty('isReady')) Block = BloqueProductosMarca;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueExperienciasCategoria`:
        Block = BloqueExperienciasCategoria;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueCardsVideo`:
        Block = BloqueCardsVideo;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueAccesorios`:
        Block = BloqueAccesorios;
        break;
      case `${type}_${typeFlexible}_Flexible_Marcas`:
        Block = Marcas;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueExperiencias`:
        Block = BloqueExperiencias;
        break;
      case `${type}_${typeFlexible}_Flexible_Breadcrumbs`:
        Block = BreadCrumbs;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueH1`:
        Block = BloqueH;
        break;
      case `${type}_${typeFlexible}_Flexible_ListOfCategories`:
        Block = ListOfCategories;
        break;
      case `${type}_${typeFlexible}_Flexible_ListOfCategoriesImages`:
        Block = ListOfCategoriesImages;
        break;
      case `${type}_${typeFlexible}_Flexible_HeadBigcrafters`:
        Block = HeadBigcrafters;
        break;
      case `${type}_${typeFlexible}_Flexible_Stepper`:
        Block = Stepper;
        break;
      case `${type}_${typeFlexible}_Flexible_StepperNumeros`:
        Block = StepperNumeros;
        break;
      case `${type}_${typeFlexible}_Flexible_StepperSlider`:
        Block = StepperSlider;
        break;
      case `${type}_${typeFlexible}_Flexible_Ventajas`:
        Block = Ventajas;
        break;
      case `${type}_${typeFlexible}_Flexible_BannerStrip`:
        Block = BannerStripComplete;
        break;
      case `${type}_${typeFlexible}_Flexible_TextColumns`:
        Block = TextColumns;
        break;
      case `${type}_${typeFlexible}_Flexible_Faqs`:
        Block = Faqs;
        break;
      case `${type}_${typeFlexible}_Flexible_FaqsImagen`:
        Block = FaqsImagen;
        break;
      case `${type}_${typeFlexible}_Flexible_BackgroundVideoText`:
        Block = BackgroundVideoText;
        break;
      case `${type}_${typeFlexible}_Flexible_GridProductBySeller`:
      case `${type}_${typeFlexible}_Flexible_GridProductByBrand`:
      case `${type}_${typeFlexible}_Flexible_GridProductByCategory`:
        // TODO: Intentar reemplazar por un componente que soporte el prop bloque y que
        // posteriormente haga el spread de las props.
        // NO SE HA HECHO ASI porque esto provoca que se quede en bucle infinito la web
        // y no sea capaz de renderizar nada.
        if (bloque.hasOwnProperty('serverState')) Block = Category;
        // TODO: Es necesario pasarle los props al componente sin el prop bloque, esto se
        // debería de cambiar para mantener la interfaz
        otherProps = bloque;
        break;
      case `${type}_${typeFlexible}_Flexible_RecommendsConnectif`:
        Block = RecommendsConnectif;
        break;
      case `${type}_${typeFlexible}_Flexible_SliderCards`:
        Block = SliderCards;
        break;
      case `${type}_${typeFlexible}_Flexible_Votacion`:
        Block = Votaciones;
        break;
      case `${type}_${typeFlexible}_Flexible_BannerUltraslim`:
        Block = BannerUltraSlim;
        break;
      case `${type}_${typeFlexible}_Flexible_Mosaic`:
        Block = Mosaic;
        break;
      case `${type}_${typeFlexible}_Flexible_ShadowBoxSlider`:
        Block = SliderShadowBox;
        break;
      case `${type}_${typeFlexible}_Flexible_Timeline`:
        Block = TimeLine;
        break;
      case `${type}_${typeFlexible}_Flexible_Recipe`:
        Block = Recipe;
        break;
      case `${type}_${typeFlexible}_Flexible_StartChatbot`:
        Block = StartChatbot;
        break;
      case `${type}_${typeFlexible}_Flexible_Blog`:
        Block = Blog;
        break;
      case `${type}_${typeFlexible}_Flexible_VideoImage`:
        Block = VideoImage;
        break;
      default:
        if (!isProduction())
          Block = () => (<div>{bloque.fieldGroupName}</div>) as JSX.Element;
        break;
    }

    return Block ? (
      <Block
        bloque={bloque}
        id={`seccion-${index}`}
        key={index}
        productos={productos}
        className={className}
        itemIndex={index}
        {...otherProps}
      />
    ) : (
      Block
    );
  });

FlexibleContent.displayName = 'FlexibleContent';
