import { forwardRef } from 'react';
import { oneLine } from 'common-tags';

import type { TypeIconButton } from './types';

/**
 * Primary UI component for user interaction
 */
const IconButtonBase = (
  {
    as: HtmlTag = 'button',
    style = 'solid',
    size = 'normal',
    className = '',
    children,
    styleDisabled = true,
    ...props
  }: TypeIconButton,
  ref: React.Ref<any>,
) => (
  <HtmlTag
    className={oneLine`flex items-center justify-center rounded-full ${props?.disabled && styleDisabled ? 'pointer-events-none opacity-20' : ''} ${size === 'small' ? 'h-7 w-7' : ''} ${size === 'normal' ? 'h-12 w-12' : ''} ${size === 'medium' ? 'h-12 w-12 md:h-11 md:w-11' : ''} ${size === 'big' ? 'h-12 w-12 md:h-[60px] md:w-[60px]' : ''} ${
      style === 'solid'
        ? 'bg-secondary-500 text-typo-alternative hover:bg-secondary-600'
        : ''
    } ${
      style === 'dashed-outline'
        ? 'border border-dashed border-primary-900 bg-transparent hover:border-none hover:bg-primary-900 hover:text-typo-alternative'
        : ''
    } ${style === 'outline' ? 'hover:text-secondary-500' : ''} ${className} `}
    ref={ref}
    {...props}
  >
    {children}
  </HtmlTag>
);

export const IconButton = forwardRef<any, TypeIconButton>(IconButtonBase);
